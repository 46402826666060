import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCUcbJhSEqV50Ww_OBRya6_WIzU3nwZAwo",
  authDomain: "mcat-29f39.firebaseapp.com",
  projectId: "mcat-29f39",
  storageBucket: "mcat-29f39.appspot.com",
  messagingSenderId: "443202789670",
  appId: "1:443202789670:web:5c3f4c832835abf82de1e7",
  measurementId: "G-ZQ7BBV3YBT",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
