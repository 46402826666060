import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { TextField, Button, Alert } from "@mui/material";
import { User } from "../Interfaces/User";
import Test from "../Interfaces/Test/Test";
import Spinner from "./Spinner";
import AxiosUtil from "../Utils/AxiosUtil";
import { ENDPOINTS } from "../Requests/Endpoints";

const Register: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      setLoading(true);
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const firebaseUser = userCredential.user;
      let tests: Test[] = [];
      const test1: Test = {
        paid: true,
        number: 1,
        answers: [],
        score: "",
      };
      tests.push(test1);
      for (let i = 2; i <= 10; i++) {
        const test: Test = {
          paid: false,
          number: i,
          answers: [],
          score: "",
        };
        tests.push(test);
      }
      const user: User = {
        id: firebaseUser.uid,
        username: firebaseUser.email ?? "",
        tests: tests,
      };
      const url = `${ENDPOINTS.addUser}`;
      const response = await AxiosUtil.post<String, User>(url, user);
      console.log("Registration successful!", response);
      navigate("/login");
    } catch (error: any) {
      setError(error.message);
      console.error("Registration error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div style={{ maxWidth: "300px", margin: "0 auto", padding: "20px" }}>
          <h2>Register</h2>
          {error && <Alert severity="error">{error}</Alert>}
          <TextField
            fullWidth
            type="text"
            label="Email"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            type="password"
            label="Password"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegister}
            style={{ marginTop: "20px" }}
          >
            Register
          </Button>
        </div>
      )}
    </>
  );
};

export default Register;
