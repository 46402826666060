import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
} from "@mui/material";
import Purchase from "../Interfaces/Payment/Purchase";
import AxiosUtil from "../Utils/AxiosUtil";
import { ENDPOINTS } from "../Requests/Endpoints";
import { auth } from "../index";
import { useAuthState } from "react-firebase-hooks/auth";

interface PurchaseHistoryProps {
  goBack: any;
}

const PurchaseHistory: React.FC<PurchaseHistoryProps> = ({ goBack }) => {
  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [user] = useAuthState(auth);

  useEffect(() => {
    const fetchPurchases = async (email: string) => {
      const url = `${ENDPOINTS.getPaymentsByUserId}?username=${email}`;
      const paymentResponse = await AxiosUtil.get<Purchase[], String>(url, "");
      setPurchases(paymentResponse);
    };
    fetchPurchases(user?.email ?? "");
  }, []);

  return (
    <>
      <div>
        <Typography variant="h4" gutterBottom>
          Purchase History
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Test Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {purchases?.map((purchase) => (
              <TableRow key={purchase.id}>
                <TableCell>{purchase.id}</TableCell>
                <TableCell>{purchase.username}</TableCell>
                <TableCell>{new Date(purchase.date).toDateString()}</TableCell>
                <TableCell>${purchase.amount}</TableCell>
                <TableCell>{purchase.testNumber}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Button
          variant="contained"
          color="primary"
          onClick={() => goBack(false)}
          style={{ marginTop: "20px" }}
        >
          Go Back to Tests
        </Button>
      </div>
    </>
  );
};

export default PurchaseHistory;
