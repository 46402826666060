import React from "react";
import { Grid, Typography } from "@mui/material";

const ServiceDescription = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={10} sm={8} md={6} lg={4}>
        <Typography variant="h2" gutterBottom>
          Description of Services
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Product Description:</strong> MCAT Prep HQ, LLC provides an
          online MCAT test-taking tool that accurately and completely simulates
          the assessment environment of the actual CARS portion of the MCAT. It
          offers an extremely detailed explanation of why a particular answer is
          correct/incorrect, and also teaches users strategies to improve their
          reasoning abilities specifically as would be applicable for the MCAT
          CARS assessment.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Product Comparison:</strong> This product offers the closest
          simulation of the actual test-taking environment currently available
          anywhere. A person using this product would greatly improve their
          ability to obtain a superior score on the CARS portion of the MCAT.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Accreditations/Intellectual Property:</strong> All the
          passages, questions, answers and explanations have been fully
          copyrighted.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Lifecycle:</strong> All of the assessments are completed and
          available. Additionally, they have been uniformly and repeatedly
          vetted to ensure the highest standards of both reliability and
          validity.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Pricing:</strong> Our pricing structure is tiered and allows
          for purchase of either the full complement of tests of a portion
          thereof.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Distribution Strategy:</strong> Our product will be available
          online. We have a comprehensive plan to enhance our online presence
          and ensure that our target population becomes aware of our product.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Fulfillment:</strong> As our product is completely digital, we
          have created a landing page and the appropriate linkages that allow
          for registration, logins, and payments to be accomplished easily and
          securely.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Requirements:</strong> Users will need access to a desktop or
          mobile device.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ServiceDescription;
