import React from "react";
import { Grid, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={10} sm={8} md={6} lg={4}>
        <Typography variant="h2" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom fontWeight="bold">
          This privacy policy discloses the privacy practices for MCAT Prep HQ.
          This privacy policy applies solely to information collected by this
          web site. It will notify you of the following:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>
              What personally identifiable information is collected from you
              through the web site, how it is used and with whom it may be
              shared.
            </li>
            <li>
              What choices are available to you regarding the use of your data.
            </li>
            <li>
              The security procedures in place to protect the misuse of your
              information.
            </li>
            <li>How you can correct any inaccuracies in the information.</li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom fontWeight="bold">
          Information Collection, Use, and Sharing:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>
              We are the sole owners of the information collected on this site.
              We only have access to/collect information that you voluntarily
              give us via email or other direct contact from you. We will not
              sell or rent this information to anyone. We will use your
              information to respond to you regarding the reason you contacted
              us. We will not share your information with any third party
              outside of our organization, other than as necessary to fulfill
              your request, e.g. to ship an order.
            </li>
            <li>
              Unless you ask us not to, we may contact you via email in the
              future to tell you about specials, new products or services, or
              changes to this privacy policy.
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom fontWeight="bold">
          Your Access to and Control Over Information. You may opt out of any
          future contacts from us at any time. You can do the following at any
          time by contacting us via the email address given on our website:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>See what data we have about you, if any.</li>
            <li>Change/correct any data we have about you.</li>
            <li>Have us delete any data we have about you.</li>
            <li>Express any concern you have about our use of your data.</li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom fontWeight="bold">
          Security:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>
              We take precautions to protect your information. When you submit
              sensitive information via the website, your information is
              protected both online and offline.{" "}
            </li>
            <li>
              Wherever we collect sensitive information (such as credit card
              data), that information is encrypted and transmitted to us in a
              secure way. You can verify this by looking for a closed lock icon
              at the bottom of your web browser.{" "}
            </li>
            <li>
              While we use encryption to protect sensitive information
              transmitted online, we also protect your information offline. Only
              employees who need the information to perform a specific job (for
              example, billing or customer service) are granted access to
              personally identifiable information. The computers/servers in
              which we store personally identifiable information are kept in a
              secure environment.{" "}
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom fontWeight="bold">
          Updates:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>
              Our Privacy Policy may change from time to time and all updates
              will be posted on this page.{" "}
            </li>
            <li>
              If you feel that we are not abiding by this privacy policy, you
              should contact us immediately via email.{" "}
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom fontWeight="bold">
          Registration :
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>
              In order to use this website, a user must first complete the
              registration form. During registration a user is required to give
              certain information (such as name and email address). This
              information is used to contact you about the products/services on
              our site in which you have expressed interest. At your option, you
              may also provide demographic information (such as gender or age)
              about yourself, but it is not required.{" "}
            </li>
          </ul>
        </Typography>
        <Typography variant="body1" gutterBottom fontWeight="bold">
          Surveys:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ul>
            <li>
              From time-to-time our site requests information via surveys.
              Participation in these surveys is completely voluntary and you may
              choose whether or not to participate and therefore disclose this
              information. Information requested may include contact information
              (such as name and address), and demographic information (such as
              zip code, age level). Survey information will be used for purposes
              of monitoring or improving the use and satisfaction of this site.{" "}
            </li>
          </ul>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
