import React from "react";
import { Grid, Typography } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={10} sm={8} md={6} lg={4}>
        <Typography variant="h2" gutterBottom>
          Refund Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          Since MCAT Prep HQ is offering non-tangible irrevocable goods we do
          not issue refunds once the order is accomplished and the product is
          sent. As a customer you are responsible for understanding this upon
          purchasing any item at our site. However, we realize that exceptional
          circumstance can take place with regard to the character of the
          product we supply.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Therefore, we DO honor requests for the refund on the following
          reasons:
        </Typography>
        <Typography variant="h5" gutterBottom>
          Non-delivery of the product:
        </Typography>
        <Typography variant="body1" gutterBottom>
          due to some mailing issues of your e-mail provider or your own mail
          server you might not receive a delivery e-mail from us. In this case
          we recommend contacting us for assistance. Claims for non-delivery
          must be submitted to us within 24 hours from order placing. Otherwise,
          the product will be considered received and downloaded.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Downloading issues:
        </Typography>
        <Typography variant="body1" gutterBottom>
          it may happen so that you are having problems while downloading the
          product. Claims regarding such issues must be submitted to us. If you
          do not properly contact us during this period, you agree that we may
          construe silence as a successful download of the product with no
          further right of redress or refund for a “download issue” reason.
          Failure to receive assistance for downloading within 24 hours may
          result in a refund decline.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Major defects:
        </Typography>
        <Typography variant="body1" gutterBottom>
          although all products are thoroughly tested before release, unexpected
          errors may occur. Such issues must be submitted to us. We keep the
          right to rectify the error or defect within 72 hours. If any
          deficiency is approved and we fail to correct it within 72 hours from
          the date of the initial complaint or any other notification provided
          by you, the refund will be issued to you in full, or, at your choice,
          replacement of product can be offered. Please be advised that
          temporary access to your server can be requested by us in order to
          identify and fix the possible issues with product. Failure to provide
          such access in a timely manner may result in a delayed resolution of
          the issue. Refusal to provide access to your sever will result in your
          inability to qualify for a refund.
        </Typography>
        <Typography variant="h5" gutterBottom>
          Product not-as-described:
        </Typography>
        <Typography variant="body1" gutterBottom>
          such issues should be reported to us within 24 hours from the time of
          purchase. Clear evidence must be provided proving that the purchased
          product is not as it is described on the website. Complaints which are
          based merely on the customer’s false expectations or wishes are not
          honored.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <i>
            Please note that we do not bear any responsibility and therefore we
            do not satisfy any refund/return/exchange requests based on
            incompatibility of our products with some third-party software
            (plug-ins, add-ons, modules, search engines, scripts, extensions
            etc). We don’t guarantee that our products are fully compatible with
            any third-party programs and we do not provide support for
            third-party applications.
          </i>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
