import React, { useState, useEffect } from "react";
import { Passage } from "../Interfaces/Passage";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Typography,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { auth } from "../index";
import { useAuthState } from "react-firebase-hooks/auth";
import xmark from "../Images/x-mark.png";
import checkmark from "../Images/checkmark.png";
import AxiosUtil from "../Utils/AxiosUtil";
import { ENDPOINTS } from "../Requests/Endpoints";
import { User } from "../Interfaces/User";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";

const TestPage: React.FC = () => {
  const navigate = useNavigate();
  const [firebaseUser] = useAuthState(auth);
  const [searchParams] = useSearchParams();
  const testNumber = searchParams.get("testNumber");
  const [showExplanations, setShowExplanations] = useState(false);
  const [isTestFinished, setIsTestFinished] = useState(false);
  const [passageData, setPassageData] = useState<Passage | null>(null);
  const [passageNumber, setPassageNumber] = useState(1);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(90 * 60);
  const [allAnswers, setAllAnswers] = useState<{
    [passage: string]: { [question: string]: string };
  }>({});
  const [selectedAnswers, setSelectedAnswers] = useState<{
    [key: string]: string;
  }>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadJsonData(`/tests/test${testNumber}/passage${passageNumber}.json`);
  }, [passageNumber, testNumber]);

  const loadJsonData = async (url: string) => {
    try {
      const response = await fetch(url);
      const data: Passage = await response.json();
      setPassageData(data);
    } catch (error) {
      console.error("Error loading JSON data:", error);
    }
  };

  const getJsonData = async (url: string) => {
    try {
      const response = await fetch(url);
      const data: Passage = await response.json();
      return data;
    } catch (error) {
      console.error("Error loading JSON data:", error);
    }
  };

  const backToLogin = async () => {
    navigate("/login");
  };

  const handleFinishTest = async () => {
    setIsLoading(true);
    setDialogOpen(false);
    setShowExplanations(true);
    setIsTestFinished(true);
    const totalQuestions = 55;
    let correctAnswers = 0;
    for (let i = 1; i <= 9; i++) {
      const passageKey = `passage${i}`;
      const userAnswers = allAnswers[passageKey];
      if (!userAnswers) continue;
      const data = await getJsonData(
        `/tests/test${testNumber}/passage${i}.json`
      );
      data?.test?.questions?.forEach((eachQuestion) => {
        const questionNumber = eachQuestion.number.toString();
        const userAnswer = userAnswers[questionNumber];
        if (userAnswer && userAnswer === eachQuestion.correctAnswer) {
          correctAnswers++;
        }
      });
    }
    alert(`score: ${correctAnswers}/${totalQuestions}`);
    const getUserurl = `${ENDPOINTS.getUserById}?id=${firebaseUser?.uid}`;
    const user = await AxiosUtil.get<User, String>(getUserurl, "");
    user?.tests?.forEach((eachTest) => {
      if (testNumber?.toString() === eachTest.number.toString()) {
        eachTest.score = `${correctAnswers}/${totalQuestions}`;
      }
    });
    const updateUserUrl = `${ENDPOINTS.updateUser}`;
    await AxiosUtil.post(updateUserUrl, user);
    setIsLoading(false);
  };

  useEffect(() => {
    setSelectedAnswers(allAnswers[`passage${passageNumber}`] || {});
  }, [passageNumber, allAnswers]);

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => Math.max(prevTime - 1, 0));
      if (timeRemaining === 0) {
        clearInterval(timerInterval);
        handleFinishTest();
      }
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timeRemaining]);

  const formatTime = (timeInSeconds: number) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleOptionChange = (
    questionNumber: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const updatedAnswers = {
      ...selectedAnswers,
      [questionNumber]: event.target.value,
    };
    setSelectedAnswers(updatedAnswers);
    setAllAnswers((prev) => ({
      ...prev,
      [`passage${passageNumber}`]: updatedAnswers,
    }));
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const correctAnswer = (question: any) => {
    try {
      const selectedAnswer = selectedAnswers[question.number.toString()];
      return selectedAnswer === question.correctAnswer;
    } catch {
      return false;
    }
  };

  return (
    <>
      {passageData &&
        (isLoading ? (
          <Spinner />
        ) : (
          <div>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography variant="h4" gutterBottom>
                  {passageData.test.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  color={timeRemaining <= 300 ? "error" : "initial"}
                >
                  {formatTime(timeRemaining)}
                </Typography>
              </Grid>
            </Grid>

            <Typography variant="body1" gutterBottom>
              {passageData.test.instructions}
            </Typography>

            <Grid container spacing={4}>
              {/* Passage section */}
              <Grid item xs={12} md={6}>
                <Paper elevation={3} style={{ padding: "16px" }}>
                  <Typography variant="h5" gutterBottom>
                    {passageData.test.passage.title}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Author:</strong> {passageData.test.passage.author}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <strong>Source: </strong>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: passageData.test.passage.source,
                      }}
                    />
                  </Typography>
                  {passageData.test.passage.paragraphs.map(
                    (paragraph, index) => (
                      <Typography
                        key={index}
                        variant="body1"
                        paragraph
                        dangerouslySetInnerHTML={{ __html: paragraph }}
                      />
                    )
                  )}
                </Paper>
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                  <Button
                    variant="contained"
                    onClick={() => setPassageNumber(passageNumber - 1)}
                    disabled={passageNumber === 1}
                    style={{ marginRight: "10px" }}
                  >
                    Previous
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenDialog}
                    style={{ marginRight: "10px" }}
                  >
                    Finish Test
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setPassageNumber(passageNumber + 1)}
                    disabled={passageNumber === 9}
                  >
                    Next
                  </Button>
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
                    <Button
                      key={number}
                      variant="contained"
                      style={{ marginRight: "10px", marginBottom: "10px" }}
                      onClick={() => setPassageNumber(number)}
                    >
                      {number}
                    </Button>
                  ))}
                </div>
              </Grid>

              {/* Questions section */}
              <Grid item xs={12} md={6}>
                {passageData.test.questions.map((question, index) => (
                  <div key={index}>
                    <Typography variant="body1" gutterBottom>
                      {isTestFinished && !correctAnswer(question) && (
                        <img
                          src={xmark}
                          alt=""
                          style={{ width: "15px", height: "auto" }}
                        />
                      )}
                      {isTestFinished && correctAnswer(question) && (
                        <img
                          src={checkmark}
                          alt=""
                          style={{ width: "15px", height: "auto" }}
                        />
                      )}
                      <strong>Question {question.number}:</strong>{" "}
                      <span
                        dangerouslySetInnerHTML={{ __html: question.text }}
                      />
                    </Typography>

                    <FormControl component="fieldset" margin="normal">
                      <RadioGroup
                        aria-label={`question-${index}`}
                        value={selectedAnswers[question.number] || ""}
                        onChange={(event) =>
                          handleOptionChange(question.number, event)
                        }
                      >
                        {question.options.map((option, optionIndex) => (
                          <React.Fragment key={optionIndex}>
                            <FormControlLabel
                              value={option.letter}
                              control={<Radio disabled={isTestFinished} />}
                              label={
                                <React.Fragment>
                                  {`${option.letter}: `}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: option.option,
                                    }}
                                  />
                                </React.Fragment>
                              }
                            />
                            {showExplanations && (
                              <Typography
                                variant="body1"
                                style={{
                                  color:
                                    option.letter === question.correctAnswer
                                      ? "blue"
                                      : "red",
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: option.explanation,
                                }}
                              />
                            )}
                            <br></br>
                          </React.Fragment>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    {index !== passageData.test.questions.length - 1 && (
                      <Divider style={{ margin: "20px 0" }} />
                    )}
                  </div>
                ))}
              </Grid>
            </Grid>

            {/* Finish Test Confirmation Dialog */}
            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
              <DialogTitle>Confirm Finish Test</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to finish the test?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Cancel</Button>
                {!isTestFinished && (
                  <Button onClick={handleFinishTest} color="primary">
                    Finish
                  </Button>
                )}
                {isTestFinished && (
                  <Button onClick={backToLogin} color="primary">
                    Back To Login
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
        ))}
    </>
  );
};

export default TestPage;
