import React from "react";
import { Grid, Typography } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <Grid item xs={10} sm={8} md={6} lg={4}>
        <Typography variant="h2" gutterBottom>
          Terms and Conditions of Use
        </Typography>
        <Typography variant="h4" gutterBottom>
          IX. User Content and Conduct
        </Typography>
        <Typography variant="body1" gutterBottom>
          Where applicable at any of the Sites, you are invited to post your own
          content ("User Content"). Please note that the Sites are intended for
          adult use; if you are under the age of 13, do not submit any User
          Content to any of the Sites. You understand that all User Content,
          whether you have publicly posted on a forum or privately transmitted
          to another user or to us, is your sole responsibility. Though the
          Sites are designed to be a safe place to share such User Content, MCAT
          Prep HQ, LLC cannot guarantee that other users will not misuse the
          User Content that you share. If you have any User Content that you
          would like to keep confidential and/or do not want others to use, do
          not post it to any of the Sites. By posting any User Content at the
          Site, you hereby grant MCAT Prep HQ, LLC a royalty-free, fully paid
          up, perpetual, irrevocable, non-exclusive and fully sublicensable
          right and license to use, reproduce, modify, adapt, publish,
          translate, combine with other works, create derivative works from,
          distribute, perform, edit and display such User Content (in whole or
          part) worldwide and/or to incorporate it in other works in any form,
          media, or technology now known or later developed. You specifically
          waive any "moral rights" in and to the User Content. The foregoing
          grant includes, without limitation, any copyrights and other
          intellectual property rights in and to your User Content. You
          represent and warrant that: you own the User Content posted by you on
          or through any of the Sites or otherwise have the right to grant the
          license set forth above; and the posting of your User Content on or
          through any of the Sites does not violate the privacy rights,
          publicity rights, copyrights, contract rights or any other rights of
          any person. You agree to pay for all royalties, fees, and any other
          monies owing any person by reason of any User Content you posted to or
          through any of the Sites. You acknowledge and agree that MCAT Prep HQ,
          LLC may preserve User Content and may also disclose User Content if
          required to do so by law or in the good faith belief that such
          preservation or disclosure is reasonably necessary to: comply with
          legal process; enforce this Agreement; respond to claims that any User
          Content violates the rights of third-parties; or protect the rights,
          property, or personal safety of MCAT Prep HQ, LLC, its users and the
          public. You understand that the technical processing and transmission
          of any of the Sites, including your User Content, may involve:
          transmissions over various networks and changes to conform and adapt
          to technical requirements of connecting networks or devices. You agree
          to each of the conditions in this Agreement and further agree that
          each of these conditions applies forever and broadly with regard to
          MCAT Prep HQ, LLC worldwide. Posting of User Content to or through any
          of the Sites, including ideas or disclosures of opinions, is voluntary
          on your part. No confidential or contractual relationship is
          established by your posting of User Content or is to be implied by our
          review or subsequent use of your User Content. MCAT Prep HQ, LLC shall
          not be liable for any disclosure of any User Content, including
          opinion(s) or suggestion(s), you post to or through any of the Sites.
          MCAT Prep HQ, LLC SHALL BE ENTITLED TO UNRESTRICTED USE OF ANY USER
          CONTENT IT MAY RECEIVE FROM YOU AT OR THROUGH ANY OF THE SITES, FOR
          ANY PURPOSE WHATSOEVER, COMMERCIAL OR OTHERWISE, WITHOUT COMPENSATION
          TO YOU AS PROVIDER OF THE USER CONTENT
        </Typography>
        <Typography variant="body1" gutterBottom fontWeight="bold">
          You agree that you will not use the Site to:
        </Typography>
        <ol>
          <li>
            <Typography variant="body1" gutterBottom>
              upload, post, email or otherwise transmit any User Content that is
              unlawful, harmful, threatening, abusive, harassing, defamatory,
              vulgar, obscene, libelous, invasive of another's privacy, hateful,
              or racially, ethnically or otherwise objectionable;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              harm minors in any way;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              impersonate any person or entity, including, but not limited to a
              representative of MCAT Prep HQ, LLC, or falsely state or otherwise
              misrepresent your affiliation with a person or entity;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              forge headers or otherwise manipulate identifiers in order to
              disguise the origin of any User Content transmitted through any of
              the Sites;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              upload, post, email or otherwise transmit any User Content that
              you do not have a right to transmit under any law or under
              contractual or fiduciary relationships (such as inside
              information, proprietary and confidential information learned or
              disclosed as part of employment relationships or under
              nondisclosure agreements);
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              upload, post, email or otherwise transmit any User Content that
              infringes any patent, trademark, trade secret, copyright or other
              proprietary rights ("Rights") of any party;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              upload, post, email or otherwise transmit any unsolicited or
              unauthorized advertising, promotional materials, "junk mail,"
              "spam," "chain letters," "pyramid schemes," or any other form of
              solicitation, except in those areas that are designated for such
              purpose;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              upload, post, email or otherwise transmit any material that
              contains software viruses or any other computer code, files or
              programs designed to interrupt, emulate, destroy or limit the
              functionality of any computer software or hardware or
              telecommunications equipment;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              disrupt the normal flow of dialogue, cause a screen to "scroll"
              faster than other users of the Site are able to type, or otherwise
              act in a manner that negatively affects other users' ability to
              engage in real time exchanges;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              interfere with or disrupt any of the Sites or servers or networks
              connected to any of the Sites, or disobey any requirements,
              procedures, policies or regulations of networks connected to any
              of the Sites;
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              "stalk" or otherwise harass another; or
            </Typography>
          </li>
          <li>
            <Typography variant="body1" gutterBottom>
              collect or store personal data about other users of any of the
              Sites.
            </Typography>
          </li>
        </ol>
        <Typography variant="body1" gutterBottom>
          We will terminate the account and/or block users who repeatedly
          violate the intellectual property rights of any other person on any of
          the Sites.
        </Typography>
        <Typography variant="body1" gutterBottom>
          You understand that by using any of the Sites, you may be exposed to
          User Content created by others that is offensive, indecent or
          objectionable. MCAT Prep HQ, LLC does not endorse or have control over
          the User Content. User Content is not reviewed by MCAT Prep HQ, LLC
          prior to posting and does not reflect the opinions or policies of MCAT
          Prep HQ, LLC. MCAT Prep HQ, LLC makes no representations or
          warranties, express or implied as to the User Content or the accuracy
          and reliability of the User Content or any other material or
          information that you may access through any of the Sites. MCAT Prep
          HQ, LLC assumes no responsibility for monitoring any of the Sites for
          inappropriate submissions or conduct. If at any time MCAT Prep HQ, LLC
          chooses, in its sole discretion, to monitor any of the Sites, MCAT
          Prep HQ, LLC nonetheless assumes no responsibility for the User
          Content, has no obligation to modify or remove any inappropriate User
          Content, and has no responsibility for the conduct of any of the
          Sites’ users submitting any such User Content. Notwithstanding the
          foregoing, MCAT Prep HQ, LLC and its designees shall have the right to
          remove any User Content that violates this Agreement or is otherwise
          objectionable, in MCAT Prep HQ, LLC’s sole discretion. You agree that
          you must evaluate, and bear all risks associated with, the use of any
          User Content, including any reliance on the accuracy, completeness, or
          usefulness of such User Content. In this regard, you acknowledge that
          you may not rely on any User Content. You are solely responsible for
          your interactions with other users of any of the Sites. MCAT Prep HQ,
          LLC reserves the right, but has no obligation, to monitor disputes
          between you and other users and to terminate your access to any of the
          Sites, in its sole discretion.
        </Typography>
        <Typography variant="h4" gutterBottom>
          X. Jurisdiction; Dispute Resolution & Waiver of Class, Consolidated
          and Representative Actions
        </Typography>
        <Typography variant="h5" gutterBottom>
          IT IS IMPORTANT THAT YOU READ THIS SECTION OF THE AGREEMENT CAREFULLY.
          This Section X of the Agreement shall be governed by and construed in
          accordance with the Federal Arbitration Act and applicable federal
          laws.
        </Typography>
        <Typography variant="body1" gutterBottom>
          This Section X of the Agreement shall be governed by and construed in
          accordance with the Federal Arbitration Act and applicable federal
          laws. Any dispute or claim relating in any way to your use of any of
          the Sites, your participation in any SMS or text message program
          offered by MCAT Prep HQ, LLC, your receipt of any communications from
          MCAT Prep HQ, LLC (including, but not limited to, any text messages or
          emails), or to any products or services sold or distributed by MCAT
          Prep HQ, LLC or through any of the Sites shall be resolved by binding
          arbitration, rather than in court, except that you may assert claims
          in small claims court if your claims qualify. ARBITRATION OF A
          DISPUTE, CLAIM OR CAUSE OF ACTION SHALL BE IN AN INDIVIDUAL CAPACITY
          AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
          REPRESENTATIVE PROCEEDING.
        </Typography>
        <Typography variant="body1" gutterBottom fontWeight="bold">
          You understand and agree that you are waiving your right to sue or go
          to court to assert or defend your rights under this Agreement. There
          is no judge or jury in arbitration, and court review of an arbitration
          award is limited. However, an arbitrator can award on an individual
          basis the same damages and relief as a court (including injunctive and
          declaratory relief or statutory damages), and must follow the terms of
          this Agreement as a court would.
        </Typography>
        <Typography variant="body1" gutterBottom>
          To begin an arbitration proceeding, either party must send a letter
          requesting an arbitration and describing the claim to the other party.
          To begin an arbitration proceeding against MCAT Prep HQ, LLC, you must
          send a letter requesting arbitration and describing your claim to our
          registered agent Corporation Service Company, 3731 S. Gandy St,
          Spokane, WA 99203. The arbitration will be conducted by the American
          Arbitration Association (AAA) under its rules, including the AAA's
          Supplementary Procedures for Consumer Related Disputes. The AAA's
          rules are available at www.adr.org or by calling 1-800-778-7879.
          Payment of all filing, administration and arbitrator fees will be
          governed by the AAA's rules. MCAT Prep HQ, LLC will not seek
          attorneys' fees and costs in arbitration unless the arbitrator
          determines the claims are frivolous. You may choose to have the
          arbitration conducted by telephone, based on written submissions, or
          in person in the county where you live or at another mutually agreed
          location.
        </Typography>
        <Typography variant="body1" gutterBottom fontWeight="bold">
          WE EACH AGREE THAT ANY DISPUTE RESOLUTION PROCEEDINGS WILL BE
          CONDUCTED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED
          OR REPRESENTATIVE ACTION.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We each agree that any arbitrator hearing a claim may not: (i) combine
          more than one individual’s claim or claims into a single case; (ii)
          order, require, participate in or facilitate production of class-wide
          contact information or notification of others of potential claims; or
          (iii) arbitrate any form of a class, collective, or representative
          proceeding. If for any reason a claim proceeds in court rather than in
          arbitration. <strong>WE EACH WAIVE ANY RIGHT TO A JURY TRIAL.</strong>{" "}
          We also both agree that you or we may bring suit in court to enjoin
          infringement or other misuse of intellectual property rights
        </Typography>
        <Typography variant="h4" gutterBottom>
          XI. Severability; Interpretation
        </Typography>
        <Typography variant="body1" gutterBottom>
          If any provision of this Agreement is deemed illegal or unenforceable,
          the remainder of this Agreement shall continue to be fully valid,
          binding, and enforceable. When used in this Agreement, the term
          "including" shall be deemed to be followed by the words "without
          limitation."
        </Typography>
        <Typography variant="h4" gutterBottom>
          XII. Entire Agreement
        </Typography>
        <Typography variant="body1" gutterBottom>
          This Agreement constitutes the entire and only Agreement between MCAT
          Prep HQ, LLC and each user of any of the Sites with respect to the
          subject matter of this Agreement and supersedes any and all prior or
          contemporaneous Agreements, representations, warranties and
          understandings, written or oral, with respect to the subject matter of
          this Agreement.
        </Typography>
        <Typography variant="h4" gutterBottom>
          XIII. Miscellaneous
        </Typography>
        <Typography variant="body1" gutterBottom>
          The failure of MCAT Prep HQ, LLC Parties to insist upon strict
          adherence to any term of this Agreement shall not constitute a waiver
          of such term and shall not be considered a waiver or limit that
          party's right thereafter to insist upon strict adherence to that term
          or any other term of this Agreement.
        </Typography>
        <Typography variant="h4" gutterBottom>
          XIV. Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We respect the intellectual property rights of others and require that
          users of any of the Sites do the same. If you believe that your
          proprietary work has been copied in a way that constitutes copyright
          infringement, please forward the following information to MCAT Prep
          HQ, LLC Copyright Agent, designated as such pursuant to the Digital
          Millennium Copyright Act, 17 U.S.C. § 512(c)(2), named below:
        </Typography>
        <Typography variant="body1" gutterBottom>
          <ol>
            <li>
              A physical signature of the person authorized to act on behalf of
              the owner of the copyright;
            </li>
            <li>
              A description of the copyrighted work that you claim has been
              infringed;
            </li>
            <li>
              A description of where the material that you claim is infringing
              is located on the Service;
            </li>
            <li>Your address, telephone number, and email address;</li>
            <li>
              A statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law;
            </li>
            <li>
              A statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner's
              behalf.
            </li>
          </ol>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
