import axios from "axios";
import { ApiResponse } from "../Interfaces/AxiosResponse";

class AxiosUtil {
  static async get<T, Q extends Record<string, any>>(
    url: string,
    queryParams: Q
  ): Promise<T> {
    try {
      const response: ApiResponse<T> = await axios.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async post<T, Q extends Record<string, any>>(
    url: string,
    body: Q
  ): Promise<T> {
    try {
      const response: ApiResponse<T> = await axios.post(url, body);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

export default AxiosUtil;
