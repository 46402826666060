import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
  Link,
  Box,
} from "@mui/material";
import { User } from "../Interfaces/User";
import AxiosUtil from "../Utils/AxiosUtil";
import { ENDPOINTS } from "../Requests/Endpoints";
import Spinner from "./Spinner";
import { v4 as uuidv4 } from "uuid";
import PurchaseHistory from "./PurchaseHistory";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const StrikeThrough = styled("span")({
  textDecoration: "line-through",
});

const Test: React.FC<{ user: User }> = ({ user }) => {
  const navigate = useNavigate();
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showHistory, setShowHistory] = useState(false);

  // New state for confirmation dialog
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [cvv, setCvv] = useState("");
  const [selectedTestNumber, setSelectedTestNumber] = useState<number | null>(
    null
  );
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null);
  const [selectedStrikeThroughPrice, setSelectedStrikeThroughPrice] = useState<
    number | null
  >(null);
  const [selectedTax, setSelectedTax] = useState<number | null>(null);
  const [selectedAmountCredited, setSelectedAmountCredited] = useState<
    number | null
  >(null);
  const [selectedSavings, setSelectedSavings] = useState<number | null>(null);

  const months = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const years = Array.from({ length: 10 }, (_, i) =>
    String(new Date().getFullYear() + i)
  );

  const handleOpenConfirmation = (
    testNumber: number,
    price: number,
    strikeThroughPrice: number,
    tax: number,
    amountCredited: number,
    savings: number
  ) => {
    setSelectedTestNumber(testNumber);
    setSelectedPrice(price);
    setSelectedStrikeThroughPrice(strikeThroughPrice);
    setSelectedTax(tax);
    setSelectedAmountCredited(amountCredited);
    setSelectedSavings(savings);
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
    setCardName("");
    setCardNumber("");
    setExpirationMonth("");
    setExpirationYear("");
    setCvv("");
  };

  const handlePurchase = async () => {
    if (selectedTestNumber === null || selectedPrice === null) return;

    try {
      setLoading(true);
      setConfirmationOpen(false);

      const newGuid = uuidv4();
      let testNumber = selectedTestNumber.toString();
      if (selectedTestNumber === 0) {
        testNumber = user.tests.map((test) => test.number).join(", ");
      }
      const purchase = {
        id: newGuid,
        date: new Date(),
        username: user.username,
        amount: selectedPrice.toFixed(2),
        testNumber: testNumber,
      };

      const purchaseUrl = `${ENDPOINTS.addPayment}`;
      await AxiosUtil.post(purchaseUrl, purchase);
      if (selectedTestNumber === 0) {
        user.tests.forEach((eachTest) => {
          eachTest.paid = true;
        });
      } else {
        user.tests[selectedTestNumber - 1].paid = true;
      }

      const updateUserUrl = `${ENDPOINTS.updateUser}`;
      await AxiosUtil.post(updateUserUrl, user);

      setSuccessOpen(true);
    } catch (error: any) {
      console.error("Purchase failed:", error);
      setErrorMessage(error.message || "An error occurred during purchase");
      setErrorOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePurchaseAll = async (price: number) => {
    const totalPrice = 499.99;
    const paidTests = user.tests.filter((test) => test.paid).length;
    const totalAlreadyPaid = 59.99 * paidTests;
    const tax = 499.99 * 0.08;
    handleOpenConfirmation(
      0,
      totalPrice - totalAlreadyPaid,
      599.99,
      tax,
      totalAlreadyPaid,
      100
    );
  };

  const renderTest = (testNumber: number) => {
    navigate(`/testpage?testNumber=${testNumber}`);
  };

  const renderHeaderRow = () => (
    <TableRow key={"testNumber"}>
      <TableCell>
        <Typography
          variant="body1"
          style={{ fontWeight: "bold", fontSize: "16px" }}
        >
          Test Number
        </Typography>
      </TableCell>
      <TableCell></TableCell>
      <TableCell>
        <Typography
          variant="body1"
          style={{ fontWeight: "bold", fontSize: "16px" }}
        >
          Score
        </Typography>
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  );

  const renderTableRow = (
    testNumber: number,
    strikeThroughPrice: number,
    price: number,
    tax: number,
    score: string,
    paid: boolean
  ) => (
    <TableRow key={testNumber}>
      <TableCell>{testNumber}</TableCell>
      <TableCell>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={() => renderTest(testNumber)}
          disabled={!paid}
        >
          Start
        </StyledButton>
      </TableCell>
      <TableCell>{score}</TableCell>
      {paid ? (
        <></>
      ) : (
        <TableCell>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              handleOpenConfirmation(
                testNumber,
                price,
                strikeThroughPrice,
                tax,
                0,
                10
              )
            }
          >
            Purchase
          </Button>{" "}
          <Typography variant="body2" color="textSecondary" display="inline">
            <StrikeThrough>${strikeThroughPrice}</StrikeThrough>{" "}
          </Typography>
          <Typography variant="body2" color="textPrimary" display="inline">
            ${price}
          </Typography>
        </TableCell>
      )}
    </TableRow>
  );

  const renderPurchaseAllTableRow = (
    strikeThroughPrice: string,
    price: number,
    paid: boolean
  ) => (
    <>
      {paid ? (
        <></>
      ) : (
        <TableRow>
          <TableCell>All</TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handlePurchaseAll(price)}
            >
              Purchase
            </Button>
            <Typography variant="body2" color="textSecondary" display="inline">
              <StrikeThrough>${strikeThroughPrice}</StrikeThrough>{" "}
            </Typography>
            <Typography variant="body2" color="textPrimary" display="inline">
              ${price}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );

  const testRows = Array.from({ length: 10 }, (_, index) => {
    const test = user.tests[index];
    const score = test.score === "" ? "N/A" : test.score;
    const strikethroughPrice = test.paid ? 0 : 69.99;
    const price = test.paid ? 0 : 59.99;
    const tax = price * 0.08;
    return renderTableRow(
      test.number,
      strikethroughPrice,
      price,
      tax,
      score,
      test.paid
    );
  });

  const hasUnpaidTest = user.tests.some((test) => !test.paid);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : showHistory ? (
        <PurchaseHistory goBack={setShowHistory} />
      ) : (
        <StyledContainer>
          <Link
            variant="body1"
            color="primary"
            onClick={() => setShowHistory(true)}
            sx={{ cursor: "pointer" }}
          >
            View Purchase History
          </Link>
          <Typography variant="h3">Available Tests</Typography>
          <TableContainer>
            <Table>
              <TableBody>
                {renderHeaderRow()}
                {renderPurchaseAllTableRow("599.99", 499.99, !hasUnpaidTest)}
                {testRows}
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog open={confirmationOpen} onClose={handleCloseConfirmation}>
            <DialogTitle>{`Confirm Purchase of test(s) ${
              selectedTestNumber === 0 ? "All" : selectedTestNumber
            }`}</DialogTitle>
            <DialogContent>
              <Box mb={2}>
                <Typography variant="body1" sx={{ fontSize: "20px" }}>
                  <strong>Price:</strong> ${selectedStrikeThroughPrice}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "20px" }}>
                  <strong>Savings:</strong> ${selectedSavings?.toFixed(2)}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "20px" }}>
                  <strong>Taxes and Fees:</strong> ${selectedTax?.toFixed(2)}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "20px" }}>
                  <strong>Credited Amount:</strong> $
                  {selectedAmountCredited?.toFixed(2)}
                </Typography>
                <Typography variant="body1" sx={{ fontSize: "24px" }}>
                  <strong>Total:</strong> $
                  {(selectedPrice
                    ? selectedPrice + (selectedTax ?? 0)
                    : 0
                  ).toFixed(2)}
                </Typography>
              </Box>
              <TextField
                label="Name on Card"
                fullWidth
                margin="normal"
                value={cardName}
                onChange={(e) => setCardName(e.target.value)}
              />
              <TextField
                label="Card Number"
                fullWidth
                margin="normal"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
              />
              <Box display="flex" justifyContent="space-between">
                <TextField
                  select
                  label="Expiration Month"
                  value={expirationMonth}
                  onChange={(e) => setExpirationMonth(e.target.value)}
                  margin="normal"
                  sx={{ minWidth: 120 }}
                >
                  {months.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  select
                  label="Expiration Year"
                  value={expirationYear}
                  onChange={(e) => setExpirationYear(e.target.value)}
                  margin="normal"
                  sx={{ minWidth: 120 }}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <TextField
                label="CVV"
                fullWidth
                margin="normal"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmation} color="secondary">
                Cancel
              </Button>
              <Button
                onClick={handlePurchase}
                color="primary"
                disabled={
                  !cardName ||
                  !cardNumber ||
                  !expirationMonth ||
                  !expirationYear ||
                  !cvv
                }
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          {/* Success Dialog */}
          <Dialog open={successOpen} onClose={() => setSuccessOpen(false)}>
            <DialogTitle>Success</DialogTitle>
            <DialogContent>
              <Typography>Purchase was successful!</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSuccessOpen(false)} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>

          {/* Error Dialog */}
          <Dialog open={errorOpen} onClose={() => setErrorOpen(false)}>
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
              <Typography>{errorMessage}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setErrorOpen(false)} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </StyledContainer>
      )}
    </>
  );
};

export default Test;
