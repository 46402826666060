export const BASE_URL = "https://us-central1-mcat-29f39.cloudfunctions.net";

export const ENDPOINTS = {
  addPayment: `${BASE_URL}/addPayment`,
  getPaymentsByUserId: `${BASE_URL}/getPaymentsByUserId`,
  addUser: `${BASE_URL}/addUser`,
  getUserById: `${BASE_URL}/getUserById`,
  updateUser: `${BASE_URL}/updateUser`,
};

export const ADD_PAYMENT = ENDPOINTS.addPayment;
export const GET_PAYMENTS_BY_USER_ID = ENDPOINTS.getPaymentsByUserId;

export const ADD_USER = ENDPOINTS.addUser;
export const GET_USER_BY_USER_ID = ENDPOINTS.getUserById;
export const UPDATE_USER = ENDPOINTS.updateUser;
