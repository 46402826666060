import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
  Routes,
} from "react-router-dom";
import LoginPage from "./LoginPage";
import TestPage from "./TestPage";
import Register from "./Register";
import Footer from "./Footer";
import ServiceDescription from "./ServiceDescription";
import TermsAndConditions from "./TermsAndConditions";
import RefundPolicy from "./RefundPolicy";
import PrivacyPolicy from "./PrivacyPolicy";

const App: React.FC = () => {
  return (
    <div
      style={{
        minHeight: "100vh", // Ensure the container takes the full viewport height
        display: "flex",
        flexDirection: "column", // Align content in a column
      }}
    >
      <Router>
        <div style={{ flex: 1 }}>
          {" "}
          <Routes>
            <Route path="/" element={<Navigate to="/login" />}></Route>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/testpage" element={<TestPage />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/description-of-goods"
              element={<ServiceDescription />}
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/return-refund-policy" element={<RefundPolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        </div>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
