import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../index";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  TextField,
  Button,
  Typography,
  Container,
  styled,
  Modal,
  Box,
} from "@mui/material";
import "firebase/auth";
import Spinner from "./Spinner";
import AxiosUtil from "../Utils/AxiosUtil";
import { ENDPOINTS } from "../Requests/Endpoints";
import { User } from "../Interfaces/User";
import Test from "./Test";
import { useAuthState } from "react-firebase-hooks/auth";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: theme.spacing(4),
}));

const StyledForm = styled("form")(({ theme }) => ({
  width: "100%",
  maxWidth: "300px",
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
}));

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
};

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firebaseUser] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (firebaseUser?.uid) {
          const getUserurl = `${ENDPOINTS.getUserById}?id=${firebaseUser.uid}`;
          const foundUser = await AxiosUtil.get<User, String>(getUserurl, "");
          setUser(foundUser);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [firebaseUser]);

  const register = () => {
    navigate("/register");
  };

  const handleClose = () => {
    setError(false);
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      var firebaseUser = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const url = `${ENDPOINTS.getUserById}?id=${firebaseUser.user.uid}`;
      const user = await AxiosUtil.get<User, String>(url, "");
      setUser(user);
    } catch (error) {
      console.error("Authentication error:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {user !== null ? (
        <Test user={user}></Test>
      ) : (
        <StyledContainer>
          <Typography variant="h4">Login Page</Typography>
          {loading ? (
            <Spinner></Spinner>
          ) : (
            <StyledForm>
              <TextField
                type="text"
                label="Email"
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                type="password"
                label="Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleLogin}
              >
                Log In
              </StyledButton>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={register}
              >
                Register
              </StyledButton>
            </StyledForm>
          )}
          <Modal
            open={error}
            onClose={handleClose}
            aria-labelledby="error-modal-title"
            aria-describedby="error-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography id="error-modal-title" variant="h6" component="h2">
                Invalid Credentials
              </Typography>
              <Typography id="error-modal-description" sx={{ mt: 2 }}>
                Please check your email and password.
              </Typography>
              <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
                Close
              </Button>
            </Box>
          </Modal>
        </StyledContainer>
      )}
    </>
  );
};

export default LoginPage;
